import FormikSelectMultiple from "components/FormikControls/FormikSelectMultiple";
import FormikSelect from "components/FormikControls/FormikSelect";
import FormikCheckboxLine from "components/FormikControls/FormikCheckboxLine";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import t from "./translate";

const EthnicitySelect = ({ multiple, ethnicityOptions, ratherNotSayOptionsPresent }) => {
  const {
    values: { ethnicity, ethnicityRatherNotSay },
    setFieldValue,
  } = useFormikContext();

  useEffect(() => {
    if (ethnicity.length > 0) setFieldValue("ethnicityRatherNotSay", false);
  }, [setFieldValue, ethnicity]);

  useEffect(() => {
    if (ethnicityRatherNotSay) setFieldValue("ethnicity", []);
  }, [setFieldValue, ethnicityRatherNotSay]);

  return (
    <>
      {multiple && (
        <>
          <FormikSelectMultiple
            fullWidth
            label={t("ethnicity_label")}
            name="ethnicity"
            options={ethnicityOptions}
            placeholder={t("search")}
            required
          />
          {ratherNotSayOptionsPresent && (
            <FormikCheckboxLine
              label={t("rather_not_say")}
              leftAlign
              name="ethnicityRatherNotSay"
              required
              sx={{ py: 1 }}
            />
          )}
        </>
      )}
      {!multiple && <FormikSelect label={t("ethnicity_label")} name="ethnicity" options={ethnicityOptions} required />}
    </>
  );
};

export default EthnicitySelect;
