import * as Yup from "yup";

const ethnicitySchema = (multipleEthnicities, ethnicityQuestionEnabled) => {
  let ethnicitySchema;

  if (multipleEthnicities) {
    ethnicitySchema = Yup.array().of(Yup.string());
    if (ethnicityQuestionEnabled) {
      ethnicitySchema = ethnicitySchema.min(1);
    }
  } else {
    ethnicitySchema = Yup.string();

    if (ethnicityQuestionEnabled) {
      ethnicitySchema = ethnicitySchema.required();
    }
  }

  return ethnicitySchema;
};

const getValidationSchema = ({
  raceQuestionEnabled,
  ethnicityQuestionEnabled,
  ratherNotSayOptionsPresent,
  multipleEthnicities,
}) => {
  let racesSchema = Yup.array().of(Yup.string());

  if (raceQuestionEnabled) {
    racesSchema = racesSchema.min(1);
  }

  return Yup.object({
    occupation: Yup.string().ensure(),
    ...(raceQuestionEnabled &&
      ratherNotSayOptionsPresent && {
        raceRatherNotSay: Yup.boolean().when("races", (races, schema) =>
          races.length > 0 ? schema : schema.oneOf([true], "is required"),
        ),
      }),
    ...(ratherNotSayOptionsPresent && {
      language: Yup.string(),
      languageRatherNotSay: Yup.boolean().when("language", (language, schema) =>
        !language ? schema.oneOf([true], "is required") : schema,
      ),
    }),
    ...(ethnicityQuestionEnabled &&
      ratherNotSayOptionsPresent && {
        ethnicityRatherNotSay: Yup.boolean().when("ethnicity", (ethnicity, schema) =>
          ethnicity.length > 0 ? schema : schema.oneOf([true], "is required"),
        ),
      }),
    ...(!ratherNotSayOptionsPresent && {
      ethnicity: ethnicitySchema(multipleEthnicities, ethnicityQuestionEnabled),
      language: Yup.string().required(),
      races: racesSchema,
    }),
  });
};

export default getValidationSchema;
